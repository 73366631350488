<template>
  <el-dialog
    v-model="showAccountMappingsDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-integration-account-mappings-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-integration-account-mappings-dialog-header__title">
        {{ t('button.accountMappings') }}
      </h4>
    </template>
    <template v-if="true">
      <!-- <template v-if="systemAccountList.length"> -->
      <div class="elv-integration-account-mappings-title">
        <p>{{ t('title.AccountInPlatform', { platform: 'Elven' }) }}</p>
        <p>{{ t('title.AccountInPlatform', { platform: 'Xero' }) }}</p>
      </div>
      <el-scrollbar height="376">
        <ul class="elv-integration-account-mappings-content">
          <li>
            <div>
              <h3>Assets</h3>
              <div class="elv-integration-account-mappings-item_account">
                <p>Cash</p>
                <SvgIcon
                  name="arrow-right-line"
                  width="24"
                  height="24"
                  class="elv-integration-account-mappings-arrow"
                />
                <el-select
                  v-model="accountMappings[0]"
                  popper-class="elv-integration-account-mappings-popper"
                  :suffix-icon="SelectSuffixIcon"
                >
                  <el-option
                    v-for="(account, i) in accountOptions"
                    :key="i"
                    :label="
                      account.accountId === '' && account.code === null
                        ? account.name
                        : `${account.code} ${account.name}`
                    "
                    :value="account.accountId"
                  />
                </el-select>
              </div>
            </div>
            <div>
              <div class="elv-integration-account-mappings-item_account">
                <p>Crypto asssets</p>
                <SvgIcon
                  name="arrow-right-line"
                  width="24"
                  height="24"
                  class="elv-integration-account-mappings-arrow"
                />
                <el-select
                  v-model="accountMappings[1]"
                  popper-class="elv-integration-account-mappings-popper"
                  :suffix-icon="SelectSuffixIcon"
                >
                  <el-option
                    v-for="(account, i) in accountOptions"
                    :key="i"
                    :label="
                      account.accountId === '' && account.code === null
                        ? account.name
                        : `${account.code} ${account.name}`
                    "
                    :value="account.accountId"
                  />
                </el-select>
              </div>
            </div>
            <div>
              <div class="elv-integration-account-mappings-item_account">
                <p>Accounts receivable</p>
                <SvgIcon
                  name="arrow-right-line"
                  width="24"
                  height="24"
                  class="elv-integration-account-mappings-arrow"
                />
                <el-select
                  v-model="accountMappings[2]"
                  popper-class="elv-integration-account-mappings-popper"
                  :suffix-icon="SelectSuffixIcon"
                >
                  <el-option
                    v-for="(account, i) in accountOptions"
                    :key="i"
                    :label="
                      account.accountId === '' && account.code === null
                        ? account.name
                        : `${account.code} ${account.name}`
                    "
                    :value="account.accountId"
                  />
                </el-select>
              </div>
            </div>
            <div>
              <h3>Revenue</h3>
              <div class="elv-integration-account-mappings-item_account">
                <p>Crypto sales</p>
                <SvgIcon
                  name="arrow-right-line"
                  width="24"
                  height="24"
                  class="elv-integration-account-mappings-arrow"
                />
                <el-select
                  v-model="accountMappings[3]"
                  popper-class="elv-integration-account-mappings-popper"
                  :suffix-icon="SelectSuffixIcon"
                >
                  <el-option
                    v-for="(account, i) in accountOptions"
                    :key="i"
                    :label="
                      account.accountId === '' && account.code === null
                        ? account.name
                        : `${account.code} ${account.name}`
                    "
                    :value="account.accountId"
                  />
                </el-select>
              </div>
            </div>
            <div>
              <div class="elv-integration-account-mappings-item_account">
                <p>Commission income</p>
                <SvgIcon
                  name="arrow-right-line"
                  width="24"
                  height="24"
                  class="elv-integration-account-mappings-arrow"
                />
                <el-select
                  v-model="accountMappings[4]"
                  popper-class="elv-integration-account-mappings-popper"
                  :suffix-icon="SelectSuffixIcon"
                >
                  <el-option
                    v-for="(account, i) in accountOptions"
                    :key="i"
                    :label="
                      account.accountId === '' && account.code === null
                        ? account.name
                        : `${account.code} ${account.name}`
                    "
                    :value="account.accountId"
                  />
                </el-select>
              </div>
            </div>
            <div>
              <div class="elv-integration-account-mappings-item_account">
                <p>Realized gain (loss) on crypto activities</p>
                <SvgIcon
                  name="arrow-right-line"
                  width="24"
                  height="24"
                  class="elv-integration-account-mappings-arrow"
                />
                <el-select
                  v-model="accountMappings[5]"
                  popper-class="elv-integration-account-mappings-popper"
                  :suffix-icon="SelectSuffixIcon"
                >
                  <el-option
                    v-for="(account, i) in accountOptions"
                    :key="i"
                    :label="
                      account.accountId === '' && account.code === null
                        ? account.name
                        : `${account.code} ${account.name}`
                    "
                    :value="account.accountId"
                  />
                </el-select>
              </div>
            </div>
          </li>
        </ul>
      </el-scrollbar>
    </template>
    <!-- <div v-else class="elv-integration-account-mappings-noData">
      <EmptyResult width="60px" height="60px">
        <template #content
          ><p>{{ t('message.accountMappingMessage') }}</p></template
        >
      </EmptyResult>
    </div> -->
    <template #footer>
      <elv-button height="44" width="107" round type="primary" :loading="submitLoading" @click="onSaveEntity">
        {{ t('button.finish') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { map, filter, concat } from 'lodash-es'
import { useReportStore } from '@/stores/modules/reports/index'
import SelectSuffixIcon from '@/components/Project/SelectSuffixIcon.vue'

const props = defineProps({
  type: {
    type: String,
    default: 'XERO'
  },
  mode: {
    type: String,
    default: 'edit' // create, edit
  }
})

const { t } = useI18n()
const reportStore = useReportStore()

const accountMappings: any = ref([
  'Cash and cash equivalents',
  'Digital assets',
  'Accounts receivable',
  'Operating revenue',
  'Commission income',
  'Non-operating gain(loss)'
])
const submitLoading = ref(false)
const showAccountMappingsDialog = ref(false)

const accountOptions: any = computed(() => {
  return concat(
    [
      {
        accountId: '',
        code: null,
        name: 'Create the account in Xero'
      }
    ],
    reportStore.accountMapping.integration
  )
})

const systemAccountList = computed(() => {
  if (props.mode === 'create') {
    return filter(reportStore.accountMapping.system, (item: any) => {
      return item.integrationAccountId === null
    })
  }
  return filter(reportStore.accountMapping.system, (item: any) => {
    return item.integrationAccountId !== null
  })
})

// 切换弹窗展示
const onCheckAccountMappingsDialog = () => {
  showAccountMappingsDialog.value = !showAccountMappingsDialog.value
}

const onSaveEntity = async () => {
  try {
    submitLoading.value = true
    // const params = map(systemAccountList.value, (item: any, index: number) => {
    //   return {
    //     integrationAccountMappingId: item.integrationAccountMappingId,
    //     accountId: item.accountId,
    //     integrationAccountId: accountMappings.value[index] === '' ? null : accountMappings.value[index]
    //   }
    // })
    // await ReportsApi.editAccountMapping(entityId.value, params)
    // if (props.mode === 'create') {
    //   await ReportsApi.syncJournalIntegration(entityId.value)
    //   entityStore.fetchEntityDetail(entityId.value)
    // } else {
    //   ElMessage.success(t('message.editSuccess'))
    // }
    // reportStore.fetchAccountMappingList(entityId.value)
    setTimeout(() => {
      ElMessage.success(t('message.editSuccess'))
      submitLoading.value = false
      onCheckAccountMappingsDialog()
    }, 920)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
    // } finally {
    //   submitLoading.value = false
  }
}

watch(
  () => systemAccountList.value,
  () => {
    if (systemAccountList.value.length) {
      accountMappings.value = map(systemAccountList.value, (item: any) => {
        return item.integrationAccountId !== null ? item.integrationAccountId : ''
      })
    }
  }
)
defineExpose({ onCheckAccountMappingsDialog })
</script>

<style lang="scss">
.elv-integration-account-mappings-dialog {
  width: 720px;
  height: 603;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-integration-account-mappings-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 41px 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-integration-account-mappings-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 19px;

      p {
        width: 310px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .el-scrollbar__view {
      width: 672px;
      display: flex;

      .elv-integration-account-mappings-content {
        display: flex;
        flex-direction: column;

        li {
          display: flex;
          align-items: center;
          flex-direction: column;

          h3 {
            color: #0e0f11;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 8px;
          }

          .elv-integration-account-mappings-item_account {
            display: flex;
            margin-bottom: 8px;
            align-items: center;

            p {
              width: 310px;
              height: 44px;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 10px 12px;
              border-radius: 4px;
              border: 1px solid #dde1e6;
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
              background-color: #f9fafb;
            }

            .elv-integration-account-mappings-arrow {
              margin: 0 14px;
              fill: #aaafb6;
            }
          }
        }

        .el-input {
          width: 310px;
          height: 44px;
          border-radius: 4px;

          &.el-date-editor--date {
            .el-input__prefix {
              display: none;
            }
          }

          &.is-disabled {
            background: #f9fafb;

            .el-input__wrapper {
              background: #f9fafb;
              border: 1px solid #dde1e6;
              box-shadow: none;
              filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));

              .el-input__suffix {
                svg,
                i {
                  width: 20px;
                  height: 20px;
                  fill: #d0d4d9;
                }
              }
            }

            .el-input__inner {
              color: #0e0f11;
              -webkit-text-fill-color: #0e0f11;
            }
          }
        }

        .el-input__inner {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 14px;
          color: #0e0f11;
        }

        .el-input__wrapper {
          width: -webkit-fill-available;
          height: -webkit-fill-available;
          border-radius: 4px;
          border: 1px solid #dde1e6;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
          padding: 1px 12px;
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

          .el-input__suffix {
            svg,
            i {
              width: 20px;
              height: 20px;
              fill: #838d95;
            }
          }
        }

        .el-input:not(.is-disabled) .el-input__wrapper {
          &:hover {
            border: 1px solid #7596eb !important;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
          }

          &.is_focus {
            border: 1px solid #7596eb !important;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
          }
        }

        .el-select {
          .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
            border: 1px solid #7596eb !important;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
          }
        }

        .el-input__wrapper.is-focus {
          border: 1px solid #7596eb !important;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
        }
      }
    }

    .elv-integration-account-mappings-noData {
      width: 100%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;

      .elv-result {
        margin-top: 0px;

        p {
          font-size: 16px;
          width: 276px;
          word-break: break-word;
          color: #dde1e6;
        }
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
}

.elv-integration-account-mappings-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }
  }
}
</style>
